import React, { useState, useEffect, useReducer, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TextField from "@mui/material/TextField";
import ChoosePoiImage from "../poi/inner-component/ChoosePoiImage";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Button as MuiButton,
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  Switch,
  Select,
  MenuItem,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Grid,
  Divider as MuiDivider,
  Link,
  Typography,
  FormLabel,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  FormHelperText,
  Box,
  Autocomplete,
  Chip,
  IconButton,
  InputAdornment,
  Tooltip,
  Paper,
  CircularProgress,
} from "@mui/material";
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Error as ErrorIcon,
  Notifications as NotificationsIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import { Formik } from "formik";
import * as Yup from "yup";
import Textfield from "./components/Textfield";
import CheckboxWrapper from "./components/Checkbox";
import SelectedCurrency from "./components/SelectCurrency";
import SelectCorporate from "./components/SelectCorporate";
import currencyInfo from "./Data/currency.json";
import AddressTextfield from "./components/AddressTextfield";
import axios from "axios";
import StrengthMeter from "./components/StrengthMeter";
import { spacing, width } from "@mui/system";
import MuiPhoneNumber from "material-ui-phone-number";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../redux/slices/counter";
import {
  getSnackOpen,
  getSnackClose,
  getSnackMsg,
  getSnackAlert,
} from "../../redux/slices/propertySlice";
import { getFileBase64 } from "../../helper/helper";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"; //for strength meter
import ShiftSchedule from "./components/ShiftSchedule";
import {
  UpdateProperty,
  CorporateList,
  ToggleButtonStatus,
  TimeZoneList,
  UserPropertyList,
  ModuleListData,
  ModulePermission,
  GetEditPropertyInfo,
} from "../../api";
import ImageCropper from "../../components/form-components/ImageCropper";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormControlSpacing = styled(MuiFormControl)(spacing);
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 20px 0 15px ${(props) => props.theme.spacing(2)};
`;
const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  tooltipIcon: {
    display: "inline-flex",
    verticalAlign: "middle",
    cursor: "pointer",
    marginLeft: "6px",
  },
}));
const StyledFormControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      display: "block",
      maxHeight: "250px",
      marginTop: "5px",
      overflowY: "auto",
      "& .MuiFormControlLabel-root": {
        display: "block",
      },
    },
  })
);
const ShiftFormControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    position: "relative",
    marginTop: "30px",
    "& .MuiBox-root": {
      backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#233044",
      padding: "0 0px 0 5px",
      left: 10,
      position: "absolute",
      top: "-15px",
      zIndex: "1",
      "& .MuiFormLabel-root": {
        fontSize: "0.75em",
      },
      "& .MuiIconButton-root": {
        marginLeft: "5px",
        padding: "4px",
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      paddingTop: "24px",
    },
  })
);
const StyledSwitchControl = styled((props) => <FormGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormControlLabel-root": {
      position: "relative",
      marginLeft: "0px",
      marginRight: "0px",
      paddingRight: "60px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiSwitch-root": {
        position: "absolute",
        right: "0px",
        top: "0px",
      },
    },
  })
);
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

//Styled component for stength meter.
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#1976D2", //funcProgressColor(),
  },
}));

function EditPropertyForm({ setUploadedImage }) {
  const classes = useStyles();
  const [corporateList, setCorporateList] = useState([]); //state for storing the corporate list api response
  const [timerZone, setTimerZone] = useState([]); //state for storing timezone data
  const [image, setImage] = useState(null); //state for image
  const [modules, setModules] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [editList, setEditList] = useState({});
  const [siteList, setSiteList] = useState([]);
  const navigate = useNavigate();
  const [moduleID, setModuleID] = useState(null);
  const { id } = useParams(); //Getting the edit user Id
  const [showCountry, setshowCountry] = useState(false);
  const [modulearray, setmodulearray] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [arraydata1, setArraydata1] = useState([]);
  const [arraydata2, setArraydata2] = useState([]);
  const [tovalue, setToValue] = React.useState(null); //State related to shift schedule pop-up
  const [fromvalue, setFromValue] = React.useState(null); //State related to shift schedule pop-up
  const [shiftoneopen, setShiftOne] = React.useState(false); //State related to shift schedule pop-up for opening the popup
  const [shiftName, setShiftName] = useState("");
  const [startTimer, setStartTimer] = useState("");
  const [endTimer, setEndTimer] = useState("");
  const [validateTime, setValidateTime] = useState(true);
  const [latitude, setLatitude] = useState(""); //state for latitude
  const [longitude, setLongitude] = useState(""); //State for longitude
  const [pageLoad, setPageLoad] = useState(false);
  const handleFileChange = (evt) => {
    let file = evt.target.files[0];
    getFileBase64(file, (result) => {
      setImageData(result);
      imageCropperOpen();
    });
  };

  // State for image cropper modal
  const [imageCropperDialog, setImageCropperDialog] = useState(false);
  const imageCropperOpen = () => {
    setImageCropperDialog(true);
  };
  const imageCropperClose = () => {
    setImageCropperDialog(false);
  };
  const [imageData, setImageData] = useState(null);
  const childToParent = (childData) => {
    setImageData(childData);
  };

  const imageCropperClear = () => {
    setImageData(null);
  };

  const dispatchR = useDispatch();
  const initialState = { selectedOptions: [] };
  function reducer(state, action) {
    switch (action.type) {
      case "SET_SELECTED_OPTIONS":
        return { selectedOptions: action.payload.options };

      case "REMOVE_OPTION":
        return {
          selectedOptions: state.selectedOptions.filter(
            (option) => option.LocationID !== action.payload.LocationID
          ),
        };
      default:
        throw new Error();
    }
  }
  const [state1, dispatch] = useReducer(reducer, initialState);

  const handleChangeAuto = (event, values) => {
    dispatch({ type: "SET_SELECTED_OPTIONS", payload: { options: values } });
  };

  const removeOption = (id) => {
    dispatch({ type: "REMOVE_OPTION", payload: { LocationID: id } });
  };
  // console.log("State---------", state1.selectedOptions);
  const siteSelectedArray = state1.selectedOptions.map((opt) => opt.LocationID);
  // console.log("Hellooooo", siteSelectedArray);
  const {
    corporate_id,
    location_name,
    phone,
    fax,
    mobile_activation_id,
    address,
    account_number,
    public_lost_id_found,
    lostfound_account_id,
    time_zone,
    map_direction,
    shipment_handling_fee,
    pwd_reset_days,
    locker_combo_unmask_pin,
    subscription_id,
    enable_mixed_tech,
    //logo_image,
    admin_name,
    admin_email,
    admin_sms,
    dummy_location_name,
    street,
    city,
    state,
    zip,
    country,
    currency,
    publish_dispatch,
    publish_dispatch_online,
    show_location_dar,
    shipment_status,
    ablyOnOff,
    dynamo_db,
    temp_check,
    email_client,
    fcm_enable,
    mobile_ir_logout_reminder,
    site_feature,
    is_hotel,
    Shift1Start,
    Shift1End,
    Shift2Start,
    Shift2End,
    Shift3Start,
    Shift3End,
    cookie_time_diff,
    covid_unmask_pwd,
    enroll_key,
    enroll_key_vehicle,
    ir_narrative_label,
    ir_legal_disclaimer,
    slack_call_offs,
    slack_tour_submit,
    slack_new_incident,
    slack_critical_task,
    slack_team_notify_url,
    slack_lost_found_url,
    slack_panic_alert_url,
    sms_credit,
    cost_per_credit,
    unlimited_sms,
    show_tour_count,
    add_venue,
    save_notify_button,
    poi_country,
    poi_regio,
    poi_state,
    poi_city,
    ru_id,
    shift1_time_notify,
    shift2_time_notify,
    shift3_time_notify,
    logo_image,
    lat,
    long,
    module_list,
    allow_adding_device,
    can_add_sites,
    shift_equipment_report,
  } = editList;
  // changing the format of shift times
  var shift_1S = "" + Shift1Start;
  var shift_1_SH = shift_1S.slice(0, 2);
  var shift_1_SM = shift_1S.slice(2, 5);
  var formatted_Shift_1_Start = `${shift_1_SH}:${shift_1_SM}`;

  var shift_1E = "" + Shift1End;
  var shift_1_EH = shift_1E.slice(0, 2);
  var shift_1_EM = shift_1E.slice(2, 5);
  var formatted_Shift_1_End = `${shift_1_EH}:${shift_1_EM}`;

  var shift_2S = "" + Shift2Start;
  var shift_2_SH = shift_2S.slice(0, 2);
  var shift_2_SM = shift_2S.slice(2, 5);
  var formatted_Shift_2_Start = `${shift_2_SH}:${shift_2_SM}`;

  var shift_2E = "" + Shift2End;
  var shift_2_EH = shift_2E.slice(0, 2);
  var shift_2_EM = shift_2E.slice(2, 5);
  var formatted_Shift_2_End = `${shift_2_EH}:${shift_2_EM}`;

  var shift_3S = "" + Shift3Start;
  var shift_3_SH = shift_3S.slice(0, 2);
  var shift_3_SM = shift_3S.slice(2, 5);
  var formatted_Shift_3_Start = `${shift_3_SH}:${shift_3_SM}`;

  var shift_3E = "" + Shift3End;
  var shift_3_EH = shift_3E.slice(0, 2);
  var shift_3_EM = shift_3E.slice(2, 5);
  var formatted_Shift_3_End = `${shift_3_EH}:${shift_3_EM}`;
  const initialValues = {
    checkdata: false,
    corporateName: corporate_id ? corporate_id : "",
    businessName: location_name ? location_name : "",
    mobileActivationID: mobile_activation_id ? mobile_activation_id : "",
    address: address ? address : "",
    long: long ? long : "",
    phoneNumber: phone ? phone : "",
    fax: fax ? fax : "",
    accountNumber: account_number ? account_number : "",
    //web
    publicLostFound: public_lost_id_found ? public_lost_id_found : "",
    //lfID
    lostFoundReporting: lostfound_account_id ? lostfound_account_id : "",
    //lostfound_account_id
    timeZone: time_zone ? time_zone : "",
    //locationtimeZone
    shift1start: formatted_Shift_1_Start ? formatted_Shift_1_Start : "",
    shift1end: formatted_Shift_1_End ? formatted_Shift_1_End : "",
    shift2start: formatted_Shift_2_Start ? formatted_Shift_2_Start : "",
    shift2end: formatted_Shift_2_End ? formatted_Shift_2_End : "",
    shift3start: formatted_Shift_3_Start ? formatted_Shift_3_Start : "",
    shift3end: formatted_Shift_3_End ? formatted_Shift_3_End : "",
    cookietime: cookie_time_diff ? cookie_time_diff : "",
    covidpassword: covid_unmask_pwd ? covid_unmask_pwd : "",
    enkeySMS: enroll_key ? enroll_key : "",
    enkeyvehicle: enroll_key_vehicle ? enroll_key_vehicle : "",
    mapOrientation: map_direction ? map_direction : "",
    //map_direction
    shipmentHandlingFee: shipment_handling_fee ? shipment_handling_fee : "",
    //handling_fee
    passwordResetIntervalDays: pwd_reset_days ? pwd_reset_days : "",
    //pwd_reset_days
    lockerPin: locker_combo_unmask_pin ? locker_combo_unmask_pin : "",
    //lockercombo
    subscriptionLevel: subscription_id ? subscription_id : "",
    //subscription_id
    //mixedTech: enable_mixed_tech ? enable_mixed_tech : "no",
    mixedTech: enable_mixed_tech ? enable_mixed_tech : "N",
    //used_mixed_tech_mode
    // logoImage: logo_image ? logo_image : null,
    //logo_img
    adminName: admin_name ? admin_name : "",
    //adminUserName
    adminEmail: admin_email ? admin_email : "",
    //AdminEmail
    adminSMS: admin_sms ? admin_sms : "",
    //sms
    switchone: publish_dispatch ? publish_dispatch : "N",
    //public
    switchtwo: publish_dispatch_online ? publish_dispatch_online : "N",
    switchthree: show_location_dar ? show_location_dar : 0,
    switchfour: shipment_status ? shipment_status : "TEST",
    switchfive: ablyOnOff ? ablyOnOff : 0,
    switchsix: dynamo_db ? dynamo_db : 0,
    switchseven: temp_check ? temp_check : 0,
    switcheight: email_client ? email_client : "ses",
    switchnine: fcm_enable ? fcm_enable : 0,
    switchten: mobile_ir_logout_reminder ? mobile_ir_logout_reminder : 0,
    switcheleven: site_feature ? site_feature : 0,
    switchtwelve: is_hotel ? is_hotel : 0,
    allow_adding_device: allow_adding_device ? allow_adding_device : 0,
    can_add_sites: can_add_sites ? can_add_sites : 0,
    shift_equipment_report: shift_equipment_report ? shift_equipment_report : 0,
    switchthirteen: show_tour_count ? show_tour_count : 0,
    switchforteen: add_venue ? add_venue : 0,
    incognitoPropertyName: dummy_location_name ? dummy_location_name : "",
    street: street ? street : "",
    city: city ? city : "",
    state: state ? state : "",
    country: country ? country : "",
    zip: zip ? zip : "",
    incidentreportnarrative: ir_narrative_label ? ir_narrative_label : "",
    disclaimerinincident: ir_legal_disclaimer ? ir_legal_disclaimer : "",
    selectCurrency: currency ? currency : "",
    calloffurl: slack_call_offs ? slack_call_offs : "",
    toursubmiturl: slack_tour_submit ? slack_tour_submit : "",
    newincidenturl: slack_new_incident ? slack_new_incident : "",
    criticaltaskurl: slack_critical_task ? slack_critical_task : "",
    teamnotifyurl: slack_team_notify_url ? slack_team_notify_url : "",
    lostfoundurl: slack_lost_found_url ? slack_lost_found_url : "",
    panicalerturl: slack_panic_alert_url ? slack_panic_alert_url : "",
    smscredit: sms_credit ? sms_credit : "",
    costpercredit: cost_per_credit ? cost_per_credit : "",
    unlimitedSMS: unlimited_sms ? unlimited_sms : false,
    show_notify: save_notify_button ? save_notify_button : false,
    poi_phone: "",
    poicountry: poi_country ? poi_country : "",
    poiregion: poi_regio ? poi_regio : "",
    poistate: poi_state ? poi_state : "",
    poicity: poi_city ? poi_city : "",
    ruId: ru_id ? ru_id : "",
    module_list: modulearray ? modulearray : [],
  };

  if (shift1_time_notify !== null) {
    var ts1 = "" + shift1_time_notify;
    var startTime1 = ts1.slice(0, 5);
    var endTime1 = ts1.slice(6, 11);
    var shour1 = startTime1.slice(0, 2);
    var smin1 = startTime1.slice(3, 5);
    var ehour1 = endTime1.slice(0, 2);
    var emin1 = endTime1.slice(3, 5);
    var st1 = "2020-01-01" + " " + shour1 + ":" + smin1; //`2020-01-01 ${shour1} : ${smin1}`;
    var et1 = "2020-01-01" + " " + ehour1 + ":" + emin1; //`2020-01-01 ${ehour1} : ${emin1}`;
  }
  if (shift2_time_notify !== null) {
    var ts2 = "" + shift2_time_notify;
    var startTime2 = ts2.slice(0, 5);
    var endTime2 = ts2.slice(6, 11);
    var shour2 = startTime2.slice(0, 2);
    var smin2 = startTime2.slice(3, 5);
    var ehour2 = endTime2.slice(0, 2);
    var emin2 = endTime2.slice(3, 5);
    var st2 = "2020-01-01" + " " + shour2 + ":" + smin2;
    var et2 = "2020-01-01" + " " + ehour2 + ":" + emin2;
  }
  if (shift3_time_notify !== null) {
    var ts3 = "" + shift3_time_notify;
    var startTime3 = ts3.slice(0, 5);
    var endTime3 = ts3.slice(6, 11);
    var shour3 = startTime3.slice(0, 2);
    var smin3 = startTime3.slice(3, 5);
    var ehour3 = endTime3.slice(0, 2);
    var emin3 = endTime3.slice(3, 5);
    var st3 = "2020-01-01" + " " + shour3 + ":" + smin3;
    var et3 = "2020-01-01" + " " + ehour3 + ":" + emin3;
  }

  const validationSchema = Yup.object().shape({
    corporateName: Yup.string().required("Please select corporate name."),
    businessName: Yup.string().required("Please enter business name."),
    mobileActivationID: Yup.string()
      .required("Please Enter your mobile activation ID.")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase and One Number."
      ),
    // mobileActivationID: Yup.string().required("Required"),
    address: Yup.string().required("Please enter address."),
    long: Yup.string().required("Please select address"),
    phoneNumber: Yup.string().required("Please enter phone number."),
    fax: Yup.string().matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})?[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      "Please enter a valid fax number."
    ),
    accountNumber: Yup.string().required("Please enter Account number."),
    publicLostFound: Yup.string().required(
      "Please enter public lost and found ID."
    ),
    timeZone: Yup.string().required("Please select a timezone."),
    shipmentHandlingFee: Yup.string()
      .matches(
        /(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )/,
        "Please enter a valid number."
      )
      .required("Please enter shipment handling fees."),
    passwordResetIntervalDays: Yup.number()
      .typeError("Please enter a valid number.")
      .min(30, "Please enter days in the range of 30-180.")
      .max(180, "Please enter days in the range of 30-180."),
    adminName: Yup.string().required("Please enter admin name."),
    adminEmail: Yup.string()
      .email("Invalid email.")
      .required("Please enter email address."),
    adminSMS: Yup.number()
      .integer()
      .positive("Please enter a valid number without dashes.")
      .typeError("Please enter a valid number without dashes.")
      .required("Please enter SMS."),
    module_list: Yup.array().min(1, "Please select module"),
  });

  //Handler for image upload and converting it into base 64
  // const onChange = (e) => {
  //   const files = e.target.files;
  //   const file = files[0];
  //   getBase64(file);
  // };

  // const onLoad = (fileString) => {
  //   console.log(fileString);
  //   console.log(initialValues);
  //   initialValues.logoImage = fileString;
  //   setImage(fileString);
  // };

  // const getBase64 = (file) => {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     onLoad(reader.result);
  //   };
  // };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    setPageLoad(true);
    setIsDisabled(true);
    try {
      let formData = {
        corporate_id: values.corporateName,
        location_name: values.businessName,
        mobile_activation_id: values.mobileActivationID,
        address: values.address,
        phone: values.phoneNumber,
        fax: values.fax,
        account_number: values.accountNumber,
        lat: latitude,
        long: longitude,
        public_lost_id_found: values.publicLostFound,
        lostfound_account_id: values.lostFoundReporting,
        time_zone: values.timeZone,
        map_direction: values.mapOrientation,
        shipment_handling_fee: values.shipmentHandlingFee,
        pwd_reset_days: values.passwordResetIntervalDays,
        locker_combo_unmask_pin: values.lockerPin,
        subscription_id: values.subscriptionLevel,
        enable_mixed_tech: values.mixedTech,
        logoImage: imageData ? imageData : null,
        admin_name: values.adminName,
        admin_email: values.adminEmail,
        admin_sms: values.adminSMS,
        publish_dispatch: values.switchone,
        publish_dispatch_online: values.switchtwo,
        show_location_dar: values.switchthree,
        shipment_status: values.switchfour,
        ablyOnOff: values.switchfive,
        dynamo_db: values.switchsix,
        temp_check: values.switchseven,
        email_client: values.switcheight,
        fcm_enable: values.switchnine,
        mobile_ir_logout_reminder: values.switchten,
        site_feature: values.switcheleven,
        is_hotel: values.switchtwelve,
        allow_adding_device: values.allow_adding_device,
        can_add_sites: values.can_add_sites,
        shift_equipment_report: values.shift_equipment_report,
        show_tour_count: values.switchthirteen,
        add_venue: values.switchforteen,
        Shift1Start: values.shift1start,
        Shift1End: values.shift1end,
        Shift2Start: values.shift2start,
        Shift2End: values.shift2end,
        Shift3Start: values.shift3end,
        Shift3End: values.shift3end,
        cookie_time_diff: values.cookietime,
        covid_unmask_pwd: values.covidpassword,
        enroll_key: values.enkeySMS,
        enroll_key_vehicle: values.enkeyvehicle,
        ir_narrative_label: values.incidentreportnarrative,
        ir_legal_disclaimer: values.disclaimerinincident,
        slack_call_offs: values.calloffurl,
        slack_tour_submit: values.toursubmiturl,
        slack_new_incident: values.newincidenturl,
        slack_critical_task: values.criticaltaskurl,
        slack_team_notify_url: values.teamnotifyurl,
        slack_lost_found_url: values.lostfoundurl,
        slack_panic_alert_url: values.panicalerturl,
        sms_credit: values.smscredit,
        cost_per_credit: values.costpercredit,
        unlimited_sms: values.unlimitedSMS,
        save_notify_button: values.show_notify,
        poi_number: values.poi_phone,
        poi_country: values.poicountry,
        poi_region: values.poiregion,
        poi_state: values.poistate,
        poi_city: values.poicity,
        ru_id: values.ruId,
        module_list: values.module_list,
        site_list: siteSelectedArray,
        dummy_location_name: values.incognitoPropertyName,
        street: values.street,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
        currency: values.selectCurrency,
      };
      await UpdateProperty(id, formData)
        .then((res) => {
          if (res.statusCode === 200) {
            setIsDisabled(false);
            // dispatchR(showLoader());
            // dispatchR(getMsg("Property has been updated successfully"));
            navigate({
              pathname: "/accounts/property-list",
            });
            dispatchR(getSnackMsg("Property has been updated successfully"));
            dispatchR(getSnackAlert("success"));
            dispatchR(getSnackOpen());
            setTimeout(() => {
              dispatchR(getSnackClose());
            }, 3000);
          } else {
            dispatchR(getSnackMsg("Something went wrong, please try again"));
            dispatchR(getSnackAlert("error"));
            dispatchR(getSnackOpen());
            setTimeout(() => {
              dispatchR(getSnackClose());
            }, 3000);
          }
          setPageLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setIsDisabled(false);
          setPageLoad(false);
          dispatch(getSnackMsg("Something went wrong, please try again"));
          dispatch(getSnackAlert("error"));
          dispatch(getSnackOpen());
        });
    } catch (error) {
      setErrors({ submit: error.message });
      setIsDisabled(false);
      setPageLoad(false);
      // dispatchR(hideLoader());
    }
  };
  // const mixedTechName = "mixedTech";

  //Api call for  select corporate dropdown
  const corporate = () => {
    CorporateList()
      // axios
      //   .get("corporate-list")
      .then((response) => {
        // console.log(response.data.data);
        setCorporateList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //API call for Timezone
  const timeZoneList = () => {
    TimeZoneList()
      // axios
      //   .get("timezone-list")
      .then((response) => {
        // console.log(response.data.data);
        setTimerZone(response.data);
        // setCorporateList(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckChange = (position, e) => {
    //e.persist();
    console.log("event", e);
    let items = modules.map((item, index) => {
      if (position === index) {
        const found = selectedItems.some((el) => el === item.module_value);
        console.log("found", found);
        if (!found && e.target.checked) {
          setSelectedItems([...selectedItems, item.module_value]);
        } else {
          var filter = selectedItems.filter((obj) => {
            return obj !== item.module_value;
          });
          setSelectedItems(filter);
        }
      }
    });
  };

  const handleChecked = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setmodulearray([...modulearray, value]);
    } else {
      setmodulearray(modulearray.filter((e) => e !== value));
    }
  };

  //API call for Changing the status for toggle button.
  const handleToggle = (key, value) => {
    let payload = {
      type: key,
      status: value,
    };
    ToggleButtonStatus(id, payload)
      // axios
      //   .post(`update-dispatch-status/${id}`, { type: key, status: value })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //API call for getting the Site section Dropdownlist
  const SitesList = () => {
    UserPropertyList()
      // axios
      //   .get(`user-property-list`)
      .then((res) => {
        // const data = res.data.data.map((item) => item.LocationName);
        setSiteList(res.data);
        setArraydata2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //api call for module list
  const moduleList = () => {
    ModuleListData()
      // axios
      //   .get(`module-list`)
      .then((response) => {
        // console.log("Module LIST ID------", response.data.data.id);
        setModules(response.data);
        setModuleID(response.data.id);
        // setCorporateList(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(editList);
  //Handler for getting checkbox array:
  const moduleListarray = () => {
    ModulePermission(id)
      .then((response) => {
        setmodulearray(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleCheckApi = (modID, status, e) => {
  //   console.log(modID);
  //   console.log("Status value", status);
  //   let checked = 0;
  //   if (e.target.checked) {
  //     checked = 1;
  //   }
  //   axios
  //     .post(`update-module-permission/${modID}`, { status: checked })
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // API call for getting the editable data of the user
  const uploadedFileRef = useRef();

  const getEditList = () => {
    GetEditPropertyInfo(id)
      // axios
      //   .get(`get-edit-property/${id}`)
      .then((response) => {
        setEditList(response.data);
        setArraydata1(response.data.site_list);
        setLatitude(response.data.lat);
        setLongitude(response.data.long);
        setPageLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setPageLoad(false);
      });
  };
  //Function for preselected options in site.
  const Resultant = () => {
    let result = [];
    arraydata1.forEach((obj) => {
      arraydata2.forEach((result3) => {
        if (result3.LocationID == obj) {
          result.push(result3);
        }
      });
    });
    dispatch({
      type: "SET_SELECTED_OPTIONS",
      payload: {
        options: result,
      },
    });
    // return result;
  };
  //Function for Filtering out subfields from the selected address.
  const handleAddresscomponent = (components) => {
    let address_street1 = "";
    let address_street2 = "";
    let address_street3 = "";
    let address_city;
    let address_state;
    let address_country;
    let address_zip = "";
    for (let i = 0; i < components.length; i++) {
      if (components[i].types[0] === "sublocality_level_1") {
        address_street1 = components[i]?.short_name;
      }
      if (components[i].types[0] === "sublocality_level_2") {
        address_street2 = components[i]?.short_name;
      }
      if (components[i].types[0] === "sublocality_level_3") {
        address_street3 = components[i]?.short_name
          ? components[i]?.short_name
          : "";
      }
      if (components[i].types[0] === "locality") {
        address_city = components[i]?.short_name;
      }
      if (components[i].types[0] === "administrative_area_level_2") {
        address_state = components[i]?.short_name;
      }
      if (components[i].types[0] === "administrative_area_level_1") {
        address_state = components[i]?.short_name;
      }
      if (components[i].types[0] === "country") {
        address_country = components[i]?.short_name;
      }
      if (components[i].types[0] === "postal_code") {
        address_zip = components[i]?.short_name;
      }
    }
    let fullstreet =
      address_street3 + " " + address_street2 + " " + address_street1;
    return [
      address_city,
      address_state,
      address_country,
      address_zip,
      fullstreet,
    ];
  };

  useEffect(() => {
    setPageLoad(true);
    getEditList();
    SitesList();
    corporate();
    timeZoneList();
    moduleList();
    moduleListarray();
  }, []);

  useEffect(() => {
    Resultant();
  }, [arraydata1, arraydata2]);
  const shiftOneOpen = () => {
    setShiftOne(true);
  };
  const shiftOneClose = () => {
    setShiftOne(false);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <SelectCorporate
                        name="corporateName"
                        label="Corporate name *"
                        options={corporateList}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="businessName"
                        label="Business name *"
                        m={2}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Business name"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="mobileActivationID"
                        label="Mobile Activation ID *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Key complexity 30% or greater"
                        // helperText="Key complexity: 0%"
                      />
                      <StrengthMeter
                        mobileActivationID={values.mobileActivationID}
                        BorderLinearProgress={BorderLinearProgress}
                      />
                      <FormHelperText style={{ marginLeft: "0px" }}>
                        8 character minimum with one upper case letter and one
                        number
                      </FormHelperText>
                    </FormControl>
                    <FormControl mt={6}>
                      <AddressTextfield
                        name="address"
                        label="Address *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Enter your address"
                        setLatitude={setLatitude}
                        setLongitude={setLongitude}
                        handleAddresscomponent={handleAddresscomponent}
                      />
                      {!errors.address ? (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {errors.long}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                    <FormControl mt={6}>
                      <MuiPhoneNumber
                        name="phoneNumber"
                        id="contactPhoneNumber"
                        label="Phone *"
                        value={values.phoneNumber}
                        onChange={handleChange("phoneNumber")}
                        onBlur={handleBlur}
                        defaultCountry={"us"}
                        style={{ width: "100%" }}
                        variant="outlined"
                        disableAreaCodes={true}
                        // margin="normal"
                        error={Boolean(
                          touched.phoneNumber && errors.phoneNumber
                        )}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </FormControl>

                    <FormControl mt={6}>
                      <Textfield
                        name="fax"
                        label="Fax"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Please enter fax"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="accountNumber"
                        label="Account number *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="off"
                        placeholder="Generally a 2-3 digit number"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="publicLostFound"
                        label={
                          <span>
                            Public lost and found ID *
                            <Tooltip
                              title={
                                "Value added here will be the ID entered at /app/lostfound/public_lost_item"
                              }
                              arrow
                              placement="top"
                            >
                              <ErrorIcon
                                className={classes.tooltipIcon}
                                color="primary"
                              />
                            </Tooltip>
                          </span>
                        }
                        inputProps={{ maxLength: 20 }}
                        InputLabelProps={{
                          shrink: true,
                          style: { pointerEvents: "auto" },
                        }}
                        autoComplete="off"
                        placeholder="Public enters this when reporting a lost item"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="lostFoundReporting"
                        label="Lost and found ID for reporting"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ maxLength: 10 }}
                        autoComplete="off"
                        placeholder="Usually the same as above ID"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="map-orientation"
                        sx={{
                          background: "#fff",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Map orientation
                      </InputLabel>
                      <Select
                        labelId="map-orientation"
                        id="map-orientation"
                        name="mapOrientation"
                        value={values.mapOrientation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        displayEmpty
                      >
                        {/* <MenuItem value="">Click to Select</MenuItem> */}
                        <MenuItem value={1}>North</MenuItem>
                        <MenuItem value={90}>West</MenuItem>
                        <MenuItem value={180}>South</MenuItem>
                        <MenuItem value={270}>East</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="shipmentHandlingFee"
                        label={
                          <span>
                            Lost & found handling fee *
                            <Tooltip
                              title={
                                "A premium in USD that is added to negotiated pricing for lost and found shipments. Allows SOVA to provide the lost and found system to clients at no cost"
                              }
                              arrow
                              placement="top"
                            >
                              <ErrorIcon
                                className={classes.tooltipIcon}
                                color="primary"
                              />
                            </Tooltip>
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { pointerEvents: "auto" },
                        }}
                        placeholder="Processing fee to SOVA in USD. Standard is 7.50"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="passwordResetIntervalDays"
                        label="Password reset interval days"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Range 30-180 days"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="lockerPin"
                        label="Locker combo unmask pin"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="For HR to view locker combo"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <TextField
                        label="Timezone *"
                        id="timezone"
                        name="timeZone"
                        select
                        value={values.timeZone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(touched.timeZone && errors.timeZone)}
                        helperText={touched.timeZone && errors.timeZone}
                      >
                        {timerZone.map((t) => (
                          <MenuItem value={t.name} key={t.id}>
                            {t.timezone}({t.name})
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                    <ShiftFormControl>
                      <FormControl>
                        <Box>
                          <FormLabel>Shift 1 start</FormLabel>
                          <IconButton
                            color="primary"
                            onClick={() => {
                              shiftOneOpen();
                              setShiftName("shift1");
                              setStartTimer(st1);
                              setEndTimer(et1);
                            }}
                          >
                            {shift1_time_notify === null ? (
                              <NotificationsIcon />
                            ) : (
                              <AccessTimeIcon />
                            )}
                            {/* <AccessTimeIcon /> */}
                          </IconButton>
                        </Box>
                        <Textfield
                          name="shift1start"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </ShiftFormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="shift1end"
                        label="Shift 1 end"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <ShiftFormControl>
                      <FormControl>
                        <Box>
                          <FormLabel>Shift 2 start</FormLabel>
                          <IconButton
                            color="primary"
                            onClick={() => {
                              shiftOneOpen();
                              setShiftName("shift2");
                              setStartTimer(st2);
                              setEndTimer(et2);
                            }}
                          >
                            {shift2_time_notify === null ? (
                              <NotificationsIcon />
                            ) : (
                              <AccessTimeIcon />
                            )}
                          </IconButton>
                        </Box>
                        <Textfield
                          name="shift2start"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </ShiftFormControl>

                    <FormControl mt={6}>
                      <Textfield
                        name="shift2end"
                        label="shift 2 end"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <ShiftFormControl>
                      <FormControl>
                        <Box>
                          <FormLabel>Shift 3 start</FormLabel>
                          <IconButton
                            color="primary"
                            onClick={() => {
                              shiftOneOpen();
                              setShiftName("shift3");
                              setStartTimer(st3);
                              setEndTimer(et3);
                            }}
                          >
                            {shift3_time_notify === null ? (
                              <NotificationsIcon />
                            ) : (
                              <AccessTimeIcon />
                            )}
                          </IconButton>
                        </Box>
                        <Textfield name="shift3start" />
                      </FormControl>
                    </ShiftFormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="shift3end"
                        label="Shift 3 end"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="cookietime"
                        label="Cookie time in min"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="covidpassword"
                        label="Covid-19 unmask password"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="enkeySMS"
                        label={
                          <span>
                            Enrollment key for group SMS
                            <Tooltip
                              title={
                                "Add a 3-5 digit code to create a uniue URL for enrolling employees to receive groups SMS"
                              }
                              arrow
                              placement="top"
                            >
                              <ErrorIcon
                                className={classes.tooltipIcon}
                                color="primary"
                              />
                            </Tooltip>
                          </span>
                        }
                        placeholder="10 characters max."
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{
                          shrink: true,
                          style: { pointerEvents: "auto" },
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="enkeyvehicle"
                        label={
                          <span>
                            Enrollment key for vehicles
                            <Tooltip
                              title={
                                "Add a 3-5 digit code to create a unique URL for enrolling employee vehicles"
                              }
                              arrow
                              placement="top"
                            >
                              <ErrorIcon
                                className={classes.tooltipIcon}
                                color="primary"
                              />
                            </Tooltip>
                          </span>
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { pointerEvents: "auto" },
                        }}
                        inputProps={{ maxLength: 10 }}
                      />
                    </FormControl>
                    <ShiftFormControl>
                      <FormControl>
                        <Box>
                          <FormLabel>Sites</FormLabel>
                          <IconButton>
                            <Tooltip
                              placement="top"
                              arrow
                              title="Location selected here populate in property ellipses if user logging in is Property Admin or Corporate User"
                            >
                              <ErrorIcon fontSize="small" color="primary" />
                            </Tooltip>
                          </IconButton>
                        </Box>
                        <Autocomplete
                          multiple
                          options={siteList}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.LocationName}
                          value={state1.selectedOptions}
                          renderTags={(values) =>
                            values.map((value) => (
                              <Chip
                                key={value.LocationID}
                                label={value.LocationName}
                                onDelete={() => {
                                  removeOption(value.LocationID);
                                }}
                              />
                            ))
                          }
                          getOptionSelected={(option, value) =>
                            option.LocationID === value.LocationID
                          }
                          renderOption={(props, option, { selected }) => (
                            <li
                              {...props}
                              key={option.LocationID}
                              style={{ border: "1px solid #b5b8bc" }}
                            >
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.LocationName}
                            </li>
                          )}
                          style={{ width: "100%" }}
                          onChange={handleChangeAuto}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder="Search"
                            />
                          )}
                        />
                      </FormControl>
                    </ShiftFormControl>

                    <FormControl mt={6}>
                      <FormLabel component="legend">
                        Add/Edit property logo
                      </FormLabel>
                      <Box
                        sx={{
                          width: "180px",
                          height: "180px",
                          borderRadius: "50%",
                          objectFit: "cover",
                          marginLeft: {
                            lg: "110px",
                            sm: "100px",
                            xs: "95px",
                          },
                          marginTop: "30px",
                          backgroundImage: `url(/static/img/brands/business.jpg)`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        {imageData && (
                          <img
                            alt="Remy Sharp"
                            src={imageData}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </Box>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        ref={uploadedFileRef}
                        onChange={(e) => handleFileChange(e)}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          sx={{
                            marginTop: "20px",
                            marginLeft: {
                              lg: "110px",
                              sm: "100px",
                              xs: "95px",
                            },
                          }}
                          variant="contained"
                          color="primary"
                          component="span"
                          className="customChooseImage"
                        >
                          Choose image
                        </Button>
                      </label>
                    </FormControl>

                    {/* <ChoosePoiImage
                      uploadedImage={imageData ? imageData : logo_image}
                      setUploadedImage={(file) => setImageData(file)}
                    />  */}
                    {imageData && (
                      <Button
                        sx={{ marginLeft: "10px" }}
                        onClick={imageCropperClear}
                        variant="contained"
                        color="primary"
                        component="span"
                        mt={3}
                      >
                        Clear
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl mt={3}>
                      <Textfield
                        name="adminName"
                        label="Admin name *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="John Smith"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="adminEmail"
                        label="Admin email *"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="me@you.com"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="adminSMS"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Admin SMS *"
                        placeholder="Number only, no dashes"
                        // placeholder="Number only, no dashes"
                        // helperText="Number only, no dashes"
                      />
                    </FormControl>
                    <StyledSwitchControl sx={{ marginTop: "12px" }}>
                      <FormGroup>
                        <FormControlLabel
                          label={
                            <Typography>
                              Show dispatch for this property
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchone"
                              value="N"
                              checked={values.switchone === "Y"}
                              onChange={(event, checked) => {
                                setFieldValue("switchone", checked ? "Y" : "N");
                                // let checked = checked ? "Y" : "N";
                                handleToggle(
                                  "publish_dispatch",
                                  checked ? "Y" : "N"
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Show only logged in users in officer dispatched
                              dropdown?
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchtwo"
                              value="N"
                              checked={values.switchtwo === "Y"}
                              onChange={(event, checked) => {
                                setFieldValue("switchtwo", checked ? "Y" : "N");
                                handleToggle(
                                  "publish_dispatch_online",
                                  checked ? "Y" : "N"
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Show location in DAR Report?
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchthree"
                              checked={values.switchthree === 1}
                              value={0}
                              onChange={(event, checked) => {
                                setFieldValue("switchthree", checked ? 1 : 0);
                                handleToggle(
                                  "show_location_dar",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Lost and found shipping setting
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchfour"
                              checked={values.switchfour === "LIVE"}
                              value="TEST"
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "switchfour",
                                  checked ? "LIVE" : "TEST"
                                );
                                handleToggle(
                                  "shipment_status",
                                  checked ? "LIVE" : "TEST"
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>Run ably for this property?</Typography>
                          }
                          control={
                            <Switch
                              name="switchfive"
                              checked={values.switchfive === 1}
                              value={0}
                              onChange={(event, checked) => {
                                setFieldValue("switchfive", checked ? 1 : 0);
                                handleToggle("ablyOnOff", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={<Typography>Enable DynamoDB?</Typography>}
                          control={
                            <Switch
                              name="switchsix"
                              value={0}
                              checked={values.switchsix === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchsix", checked ? 1 : 0);
                                handleToggle("dynamo_db", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Perform Covid-19 tech checks?
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchseven"
                              value={0}
                              checked={values.switchseven === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchseven", checked ? 1 : 0);
                                handleToggle("temp_check", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Enable Amazon SES for email?
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switcheight"
                              value="ses"
                              checked={values.switcheight === "sendgrid"}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "switcheight",
                                  checked ? "sendgrid" : "ses"
                                );
                                handleToggle(
                                  "email_client",
                                  checked ? "sendgrid" : "ses"
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>Enable FCM for mobile?</Typography>
                          }
                          control={
                            <Switch
                              name="switchnine"
                              value={0}
                              checked={values.switchnine === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchnine", checked ? 1 : 0);
                                handleToggle("fcm_enable", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Show mobile reminder if logging out with
                              unsubmitted IR?
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchten"
                              value={0}
                              checked={values.switchten === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchten", checked ? 1 : 0);
                                handleToggle(
                                  "mobile_ir_logout_reminder",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={<Typography>Enable site feature?</Typography>}
                          control={
                            <Switch
                              name="switcheleven"
                              value={0}
                              checked={values.switcheleven === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switcheleven", checked ? 1 : 0);
                                handleToggle("site_feature", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={<Typography>Is this site a hotel?</Typography>}
                          control={
                            <Switch
                              name="switchtwelve"
                              value={0}
                              checked={values.switchtwelve === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchtwelve", checked ? 1 : 0);
                                handleToggle("is_hotel", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Show tour button scan count in shift report?
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchthirteen"
                              value={0}
                              checked={values.switchthirteen === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "switchthirteen",
                                  checked ? 1 : 0
                                );
                                handleToggle(
                                  "show_tour_count",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Location has multiple venues?
                              <Tooltip
                                title={
                                  "Allows SOVA clients to add more than one venue, which adds values in IR venue dropdown"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="switchforteen"
                              value={0}
                              checked={values.switchforteen === 1}
                              onChange={(event, checked) => {
                                setFieldValue("switchforteen", checked ? 1 : 0);
                                handleToggle("add_venue", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Allow adding devices?
                              <Tooltip
                                title={
                                  "Allows SOVA clients to add their own mobile devices"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="allow_adding_device"
                              value={0}
                              checked={values.allow_adding_device === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "allow_adding_device",
                                  checked ? 1 : 0
                                );
                                handleToggle(
                                  "allow_adding_device",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Clients can add sites?
                              <Tooltip
                                title={
                                  "Set to yes if SOVA clients should be allowed to add their own site portals"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="can_add_sites"
                              value={0}
                              checked={values.can_add_sites === 1}
                              onChange={(event, checked) => {
                                setFieldValue("can_add_sites", checked ? 1 : 0);
                                handleToggle("can_add_sites", checked ? 1 : 0);
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          label={
                            <Typography>
                              Shift Equipment Report?
                              <Tooltip
                                title={
                                  "Set to yes if SOVA clients should be allowed to get shift equipment reports"
                                }
                                arrow
                                placement="top"
                              >
                                <ErrorIcon
                                  className={classes.tooltipIcon}
                                  fontSize="small"
                                  color="primary"
                                />
                              </Tooltip>
                            </Typography>
                          }
                          control={
                            <Switch
                              name="shift_equipment_report"
                              value={0}
                              checked={values.shift_equipment_report === 1}
                              onChange={(event, checked) => {
                                setFieldValue(
                                  "shift_equipment_report",
                                  checked ? 1 : 0
                                );
                                handleToggle(
                                  "shift_equipment_report",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </FormGroup>
                    </StyledSwitchControl>
                    <Grid item xs={12}>
                      <CheckboxWrapper
                        name="show_notify"
                        checked={values.show_notify}
                        // legend="Terms Of Service"
                        label="Only show save and notify button in attendance log"
                      />
                    </Grid>
                    <StyledFormControl sx={{ marginTop: "15px" }}>
                      <FormLabel component="legend">
                        Select modules
                        {
                          <sup
                            style={{
                              verticalAlign: "top",
                            }}
                          >
                            *
                          </sup>
                        }
                      </FormLabel>
                      <FormGroup>
                        {modules.map((mod, index) => {
                          return (
                            <FormControlLabel
                              key={mod.id}
                              control={
                                <Checkbox
                                  disableRipple
                                  name={mod.module_name}
                                  value={mod.module_value}
                                  // checked={true}
                                  checked={values.module_list.includes(
                                    mod.module_value
                                  )}
                                  onChange={(e) => {
                                    const { value, checked } = e.target;
                                    if (checked) {
                                      let filter = [
                                        ...values.module_list,
                                        value,
                                      ];
                                      setFieldValue("module_list", filter);
                                    } else {
                                      let filter = values.module_list.filter(
                                        (e) => e !== value
                                      );
                                      setFieldValue("module_list", filter);
                                    }
                                  }}
                                  // onChange={(e) => {
                                  //   // handleChecked(e);
                                  //   // handleCheckApi(mod.id, mod.status, e);
                                  //   //handleModule(mod.module_value, e, index);
                                  // }}
                                />
                              }
                              label={mod.module_name}
                            />
                          );
                        })}
                      </FormGroup>
                      <FormHelperText
                        style={{ marginLeft: "0px", color: "#d32f2f" }}
                      >
                        {errors.module_list}
                      </FormHelperText>
                    </StyledFormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="incognitoPropertyName"
                        label="Incognito property name"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="False name visible during demos"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="street"
                        label="Street"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="city"
                        label="City"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="state"
                        label="State"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="country"
                        label="Country"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="zip"
                        label="Zip"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Should autofill after entering address above"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <InputLabel
                        shrink
                        id="subs-level"
                        sx={{
                          background: "#fff",
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          marginLeft: "-4px",
                        }}
                      >
                        Subscription level
                      </InputLabel>
                      <Select
                        labelId="subs-level"
                        id="subs-level"
                        name="subscriptionLevel"
                        value={values.subscriptionLevel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        displayEmpty
                      >
                        <MenuItem value="">Click to Select</MenuItem>
                        <MenuItem value={1}>No IR</MenuItem>
                        <MenuItem value={2}>IR</MenuItem>
                        <MenuItem value={3}>BLE</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl mt={6}>
                      <FormLabel component="legend">
                        Enable mixed tech?
                      </FormLabel>
                      <RadioGroup
                        name="mixedTech"
                        row={true}
                        value={values.mixedTech.toString()}
                        onChange={(e) =>
                          setFieldValue("mixedTech", e.currentTarget.value)
                        }
                        aria-labelledby="enable-mixed-tech"
                      >
                        <FormControlLabel
                          control={<Radio />}
                          value="Y"
                          label="Yes"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          value="N"
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="incidentreportnarrative"
                        label="Phrase to begin incident report narrative with"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="disclaimerinincident"
                        label="Phrase for legal disclaimer in incident report"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <SelectedCurrency
                        name="selectCurrency"
                        label="Currency"
                        options={currencyInfo}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="calloffurl"
                        label="Slack call-offs URL"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="https://hooks.slack.com/services/{your slack ID}/{your slack channel}/{slack code here}"
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="toursubmiturl"
                        label="Slack tour-submit URL"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="newincidenturl"
                        label="Slack new-incident URL"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="criticaltaskurl"
                        label="Slack critical task URL"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="teamnotifyurl"
                        label="Slack team-notify URL"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="lostfoundurl"
                        label="Slack lost-found URL"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="panicalerturl"
                        label="Slack panic-alert URL"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="smscredit"
                        label="SMS credits"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl mt={6}>
                      <Textfield
                        name="costpercredit"
                        label="Cost per credit"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <Grid item xs={12}>
                      <CheckboxWrapper
                        name="unlimitedSMS"
                        checked={values.unlimitedSMS}
                        // legend="Terms Of Service"
                        label="Only if you want to send unlimited SMS"
                      />
                    </Grid>

                    <FormControl mt={6}>
                      <Textfield
                        name="poi_phone"
                        label="POI number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onDoubleClick={(e) => {
                          setshowCountry(!showCountry);
                          setFieldValue("poicountry", "");
                          setFieldValue("poiregion", "");
                          setFieldValue("poistate", "");
                          setFieldValue("poicity", "");
                        }}
                        autoComplete="off"
                        placeholder="Double click to change country code"
                      />
                    </FormControl>

                    {showCountry && (
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-label-two"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          Select country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-two"
                          id="demo-simple-select"
                          name="poicountry"
                          value={values.poicountry}
                          label="Select Corporate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">Click to select</MenuItem>
                          <MenuItem value="US">US</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {values.poicountry !== "" && showCountry && (
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-label-two"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          POI Region
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-two"
                          id="demo-simple-select"
                          name="poiregion"
                          value={values.poiregion}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">Click to select</MenuItem>
                          <MenuItem value="Hawaii">Hawaii</MenuItem>
                          <MenuItem value="Alaska">Alaska</MenuItem>
                          <MenuItem value="Pacific">Pacific</MenuItem>
                          <MenuItem value="Mountain">Mountain</MenuItem>
                          <MenuItem value="Central">Central</MenuItem>
                          <MenuItem value="Eastern">Eastern</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {values.poiregion !== "" && showCountry && (
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-label-two"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          POI State *
                        </InputLabel>
                        <Select
                          name="poistate"
                          value={values.poistate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">Click to select</MenuItem>
                          <MenuItem value="Utah">Utah</MenuItem>
                          <MenuItem value="Vermont">Vermont</MenuItem>
                          <MenuItem value="Virginia">Virginia</MenuItem>
                          <MenuItem value="Washington">Washington</MenuItem>
                          <MenuItem value="West Virginia">
                            West Virginia
                          </MenuItem>
                          <MenuItem value="Wisconsin">Wisconsin</MenuItem>
                          <MenuItem value="Wyoming">Wyoming</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {values.poistate !== "" && showCountry && (
                      <FormControl mt={6}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-label-two"
                          sx={{
                            paddingLeft: "6px",
                            paddingRight: "6px",
                            marginLeft: "-4px",
                          }}
                        >
                          POI City *
                        </InputLabel>
                        <Select
                          name="poicity"
                          value={values.poicity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem value="">Click to select</MenuItem>
                          <MenuItem value="Los Angeles">Los Angeles</MenuItem>
                          <MenuItem value="Chicago">Chicago</MenuItem>
                          <MenuItem value="Houston">Houston</MenuItem>
                          <MenuItem value="Dallas">Dallas</MenuItem>
                          <MenuItem value="Phoenix">Phoenix</MenuItem>
                        </Select>
                      </FormControl>
                    )}

                    <FormControl mt={6}>
                      <Textfield
                        name="ruId"
                        label="RUID"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={isDisabled}
                      mt={6}
                      sx={{ width: "175px" }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
            {/* {shiftoneopen === true && ( */}
            <ShiftSchedule
              shiftoneopen={shiftoneopen}
              shiftOneClose={shiftOneClose}
              tovalue={tovalue}
              setToValue={setToValue}
              fromvalue={fromvalue}
              setFromValue={setFromValue}
              shiftName={shiftName}
              setShiftName={setShiftName}
              startTimer={startTimer}
              endTimer={endTimer}
              validateTime={validateTime}
              setValidateTime={setValidateTime}
            />
          </Card>
        )}
      </Formik>
      {pageLoad && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      {imageCropperDialog && (
        <ImageCropper
          imageCropperDialog={imageCropperDialog}
          setImageCropperDialog={setImageCropperDialog}
          imageCropperOpen={imageCropperOpen}
          imageCropperClose={imageCropperClose}
          childToParent={childToParent}
          imageData={imageData}
          dialogHead="Choose a property logo"
        />
      )}
    </>
  );
}
function EditProperty() {
  return (
    <React.Fragment>
      <Helmet title="Edit Property" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Property
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/accounts/property-list">
          Accounts
        </Link>
        <Typography>Edit Property</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <EditPropertyForm />
    </React.Fragment>
  );
}

export default EditProperty;
