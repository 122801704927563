import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Paper,
  Typography,
  Button,
  TextField as MuiTextField,
  Box,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import useAuth from "../../hooks/useAuth";
import { login } from "../../redux/slices/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveAuditUser,
  Login,
  LoginVerify,
  VerifyCode,
  Reset2FA,
  SaveRecoveryCode,
  CheckRecoveryCode,
  ExpireRecoveryCode,
} from "../../api";
import {
  ContentCopy as ContentCopyIcon,
  Info as InfoIcon,
} from "@mui/icons-material";

const TextField = styled(MuiTextField)(spacing);
const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const OtpContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const OtpInput = styled.input`
  width: 40px;
  padding: 10px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;

  &:focus {
    border-color: #376fd0;
    outline: none;
  }
`;

function SignInForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [qrCodeSvg, setQrCodeSvg] = useState("");
  const [qrAuthentaction, setQrAuthentaction] = useState(false);
  const [modalRecoveryCode, setModalRecoveryCode] = useState(false);
  const [msg, setMsg] = useState("These credentials do not match our records.");
  const [snackopen, setSnackopen] = useState(false);
  const user = useSelector((state) => state.login.user);
  const { signIn } = useAuth();
  const [isVerificationChecked, setVerificationChecked] = useState(true);
  const [isRecoveryChecked, setRecoveryChecked] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState(""); // Recovery code to display
  const [recoveryCodeModal, setRecoveryCodeModal] = useState(false); // To open/close modal
  const [isRecoveryCodeSaved, setIsRecoveryCodeSaved] = useState(false); // Checkbox state
  const [isRecoveryCodeChecked, setIsRecoveryCodeChecked] = useState(false); // For second-time login

  const handleRecoveryCodeModalOpen = () => setRecoveryCodeModal(true);
  const handleRecoveryCodeModalClose = () => setRecoveryCodeModal(false);
  const [verify2faData, setVerify2faData] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [autenticationContent, setAutenticationContent] = useState(false);
  const handleContentOpen = () => {
    setAutenticationContent(true);
  };
  const handleContentClose = () => {
    setAutenticationContent(false);
  };

  const handleRecoveryCodeCheck = (event) => {
    setIsRecoveryCodeSaved(event.target.checked);
  };
  const copyRecoveryCode = () => {
    if (recoveryCode) {
      navigator.clipboard
        .writeText(recoveryCode)
        .then(() => {
          // Show success message
          setSnackMessage("Recovery code has been copied successfully");
          setSnackOpen(true);
        })
        .catch((err) => {
          console.error("Failed to copy recovery code: ", err);
        });
    }
  };

  // Handle close of the Snackbar

  const handleRecoveryCodeConfirm = () => {
    if (isRecoveryCodeSaved && userId !== null) {
      SaveRecoveryCode({ user_id: userId, recovery_code: recoveryCode })
        .then((response) => {
          if (response && response.status === 200) {
            console.log("Recovery code saved successfully");

            // Dispatch the stored verify2faData if it's available
            if (verify2faData) {
              dispatch(login(verify2faData.data));
              handleRecoveryCodeModalClose();
              window.location.reload();
            } else {
              console.error("verify2faData is not available.");
            }
          } else {
            console.error("Failed to save recovery code.");
          }
        })
        .catch((err) => {
          console.error("Error in saving recovery code:", err);
          if (err.message === "Unauthenticated.") {
            alert("You are not authenticated. Please log in again.");
          }
        });
    }
  };
  const generateRecoveryCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$&?"; // Set of characters including alphabets, numbers, and symbols
    let code = "";
    const codeLength = 16; // You can adjust the length if needed

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters[randomIndex];
    }

    setRecoveryCode(code);
  };
  const handleSnackRemove = () => {
    setSnackOpen(false);
  };

  // OTP inputs states
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const otpRefs = useRef([]);

  if (user != null || user != undefined) {
    navigate({ pathname: "/" });
  }

  const handleSnackClick = () => setSnackopen(true);
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackopen(false);
  };
  const handleVerificationChange = () => {
    setVerificationChecked(true);
    setRecoveryChecked(false);
  };

  const handleRecoveryChange = () => {
    setRecoveryChecked(true);
    setVerificationChecked(false);
  };

  const handleClickOpen = () => setQrAuthentaction(true);
  const handleClickClose = () => setQrAuthentaction(false);
  const handleRecovery = () => setModalRecoveryCode(true);
  const handleRecoveryClose = () => setModalRecoveryCode(true);

  const handleShowQrCode = () => {
    if (userId !== null) {
      Reset2FA({ user_id: userId })
        .then(() => {
          LoginVerify({ userId })
            .then((response) => {
              setQrCodeSvg(response.qr_code_url);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    if (element.value !== "" && index < otp.length - 1) {
      otpRefs.current[index + 1].focus();
    }
    if (newOtp.join("").length === 6) {
      handleOtpSubmit(newOtp.join(""));
    }
  };

  const handleOtpSubmit = (otpValue) => {
    if (otpValue && userId !== null) {
      const data = {
        "2fa_token": otpValue,
        user_id: userId,
      };
      VerifyCode(data)
        .then((response) => {
          if (
            response.message === "QR code already shown. Please enter your OTP."
          ) {
            dispatch(login(response.data));
            window.location.reload();
          } else {
            setVerify2faData(response);
            generateRecoveryCode();
            handleRecoveryCodeModalOpen();
            handleClickClose();
          }
        })
        .catch((err) => {
          console.log("Error verifying OTP:", err);
        });
    }
  };
  const [enteredRecoveryCode, setEnteredRecoveryCode] = useState("");

  const handleRecoveryCodeChange = (e) => {
    setEnteredRecoveryCode(e.target.value); // Set the entered recovery code
  };

  const handleLoginWithRecoveryCode = () => {
    if (!enteredRecoveryCode) {
      showAlertModal("error", "Please enter the recovery code.");
      return;
    }

    CheckRecoveryCode({ user_id: userId, recovery_code: enteredRecoveryCode })
      .then((response) => {
        if (response.status === "success" && response.data) {
          ExpireRecoveryCode({ user_id: userId }); // Expire the code after one use
          dispatch(login(response.data));
          window.location.reload();
        } else {
          showAlertModal("error", "Invalid recovery code");
        }
      })
      .catch((err) => console.log(err));
  };

  const showAlertModal = (type, message) => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const saveAuditUser = () => {
    SaveAuditUser()
      .then((res) => {
        if (res.statusCode == 200) {
          console.log("API successfully called");
        } else {
          console.log("Something went wrong, please try again");
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <Snackbar
        open={snackopen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="error"
          variant="filled"
          sx={{ maxWidth: 600 }}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Formik
        initialValues={{
          user_email: "",
          user_password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          user_email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          user_password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            let user = {
              email: values.user_email,
              password: values.user_password,
            };
            Login(user)
              .then((response) => {
                if (response.statusCode === 200) {
                  if (response.status === "2FA Required") {
                    setUserId(response.user_id);
                    LoginVerify({ userId: response.user_id })
                      .then((response) => {
                        setQrCodeSvg(response.qr_code_url);
                        handleClickOpen(response.user_id);
                      })
                      .catch((err) => console.log(err));
                  } else {
                    saveAuditUser();
                    dispatch(login(response.data));
                    window.location.reload();
                  }
                }
              })
              .catch((err) => {
                handleSnackClick();
                setStatus({ sent: true });
                setSubmitting(false);
              });
          } catch (error) {
            const message =
              error.message || "Something went wrong. Please try again!";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <TextField
                  type="email"
                  name="user_email"
                  label="User"
                  value={values.user_email}
                  error={Boolean(touched.user_email && errors.user_email)}
                  fullWidth
                  helperText={touched.user_email && errors.user_email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  mt={3}
                />
                <TextField
                  type="password"
                  name="user_password"
                  label="Password"
                  mt={6}
                  value={values.user_password}
                  error={Boolean(touched.user_password && errors.user_password)}
                  fullWidth
                  helperText={touched.user_password && errors.user_password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12} align="right">
                <NavLink to="/auth/reset-password" color="primary">
                  Forgot password
                </NavLink>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              sx={{ marginTop: "15px" }}
            >
              Sign in
            </Button>
            <Typography
              variant="body2"
              component="div"
              gutterBottom
              mt={3}
              align="center"
            >
              Copyright &copy; 2022, SOVA Systems | Open a ticket
            </Typography>
            <Box sx={{ textAlign: "center" }} mt={3}>
              <a
                target="_blank"
                href="https://support.sovasystems.com/portal/en/kb/articles/terms-for-user-sites"
                color="primary"
              >
                Use Terms
              </a>
              <span> | </span>
              <a
                target="_blank"
                href="https://support.sovasystems.com/portal/en/kb/articles/privacy-policy"
                color="primary"
              >
                Privacy Policy
              </a>
              <span> | </span>
              <a
                target="_blank"
                href="https://support.sovasystems.com/portal/en/kb/articles/master-agreement"
                color="primary"
              >
                Services Agreement
              </a>
            </Box>
          </form>
        )}
      </Formik>

      <Dialog open={qrAuthentaction} onClose={handleClickClose}>
        <DialogTitle className="qr-verify">
          {qrCodeSvg ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Scan QR code from Authenticator app
                <InfoIcon
                  onClick={handleContentOpen}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </>
          ) : (
            "Enter verification code from Authenticator app OR Use Recovery Code to login"
          )}
        </DialogTitle>
        <DialogContent className="qr-verify" sx={{ overflowY: "hidden" }}>
          {qrCodeSvg && <div dangerouslySetInnerHTML={{ __html: qrCodeSvg }} />}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={isVerificationChecked}
              onChange={handleVerificationChange}
              sx={{ padding: "0px" }}
            />
            <Typography variant="h6" sx={{ paddingLeft: "6px" }}>
              Enter Verification code
            </Typography>
          </Box>
          {isVerificationChecked && (
            <OtpContainer sx={{ marginTop: "15px" }}>
              {otp.map((digit, index) => (
                <OtpInput
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  ref={(el) => (otpRefs.current[index] = el)}
                  onChange={(e) => handleOtpChange(e.target, index)}
                />
              ))}
            </OtpContainer>
          )}
          <Box sx={{ marginTop: "30px" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={isRecoveryChecked}
                onChange={handleRecoveryChange}
                sx={{ padding: "0px" }}
              />
              <Typography variant="h6" sx={{ paddingLeft: "6px" }}>
                Enter Recovery Code
              </Typography>
            </Box>
            {isRecoveryChecked && (
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Enter your recovery code"
                value={enteredRecoveryCode} // Bind value to state
                onChange={handleRecoveryCodeChange} // Capture input changes
                mt={3}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions className="qr-verify">
          <Button onClick={handleClickClose} color="primary">
            Close
          </Button>
          {!qrCodeSvg && (
            <Button onClick={handleShowQrCode} color="primary">
              Show QR Code
            </Button>
          )}
          <Button
            onClick={() => {
              handleOtpSubmit(otp.join(""));
              handleLoginWithRecoveryCode(recoveryCode);
            }}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={recoveryCodeModal} onClose={handleRecoveryCodeModalClose}>
        <DialogContent>
          <Typography variant="body2">
            Please store the following recovery code in a safe place. You will
            need it in case you lose access to your 2FA device.
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "200px",
              border: "1px solid #dddddd",
              marginTop: "30px",
              position: "relative",
            }}
          >
            <Typography
              variant="h5"
              sx={{ marginTop: "15px", fontWeight: "bold" }}
            >
              {recoveryCode}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                position: "absolute",
                right: "0",
                bottom: "0",
              }}
            >
              <Typography variant="body2">Copy recovery code</Typography>
              <IconButton>
                <ContentCopyIcon onClick={copyRecoveryCode} color="primary" />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            <Checkbox
              checked={isRecoveryCodeSaved}
              onChange={handleRecoveryCodeCheck}
              sx={{ padding: "0px" }}
            />
            <Typography variant="body2">
              I have saved my recovery code
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRecoveryCodeConfirm}
            color="primary"
            variant="contained"
            disabled={!isRecoveryCodeSaved}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={autenticationContent} onClose={handleContentClose}>
        <DialogTitle>Two-factor authentication</DialogTitle>
        <DialogContent>
          <Typography variant="h6">What is Google Authenticator?</Typography>
          <Typography variant="body2">
            Google Authenticator is an app that helps protect your online
            accounts by adding an extra layer of security, known as two-factor
            authentication (2FA). When you log in to an account, instead of just
            entering your password, you’ll also enter a code generated by this
            app. This makes it much harder for anyone else to access your
            account, even if they know your password.
          </Typography>
          <Typography variant="h6" mt={4}>
            How It Works
          </Typography>
          <Typography variant="body2">
            After setting it up, the app generates a new code every 30 seconds.
            When logging in to SOVA, you’ll enter this code along with your
            password.
          </Typography>
          <Typography variant="h6" mt={4}>
            Why Your Business Requires It
          </Typography>
          <Typography variant="body2">
            To ensure the highest level of security for your SOVA user account,
            your company asks you to download Google Authenticator on your
            personal mobile phone. You may already have the app installed on
            your phone. If not, the app is available as a free download in both
            the Apple and Google app stores for your convenience.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={handleContentClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackRemove}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleSnackRemove} severity="success" variant="filled">
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

function SignIn() {
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Sign In" />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          SOVA Login
        </Typography>
        <SignInForm />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
